import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { CandidatosComponent } from './system/candidatos/candidatos.component';
import { PerfilCandidatoComponent } from './system/candidatos/perfil-candidato/perfil-candidato.component';
import { DashboardComponent } from './system/dashboard/dashboard.component';
import { FiltrosComponent } from './system/filtros/filtros.component';
import { MisVacantesComponent } from './system/mis-vacantes/mis-vacantes.component';
import { VacantesComponent } from './system/mis-vacantes/vacantes/vacantes.component';
import { SystemComponent } from './system/system.component';

const routes: Routes = [
  {path: 'app', component: SystemComponent, children: [
    { path: 'filtros', component: FiltrosComponent},
    { path: 'mis-vacantes', component: MisVacantesComponent },
    { path: 'mis-vacantes/:id', component: VacantesComponent },
    { path: 'candidatos', component: CandidatosComponent },
    { path: 'candidatos/:id', component: PerfilCandidatoComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: '', component: DashboardComponent }

  ]},
  {path: '', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
