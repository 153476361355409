<main>
    <div class="row">
        <div class="col-10">
            <h1>Filtros</h1>
            <p>Estos son los filtros creados para que aparezcan dentro de la aplicación. </p>
        </div>
        <div class="col-2">
            <button type="button" class="btn btn-primary btn-block" data-toggle="modal" data-target="#modal-filtro-crear">Agregar Filtro</button>
        </div>
    </div>

    <table class="table table-hover dataTables">
        <thead>
            <tr>
                <th>Tecnología</th>
                <th>Sistema</th>
                <th>Módulo</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let f of filters">
                <td>{{f.tech}}</td>
                <td>{{f.system}}</td>
                <td>{{f.module}}</td>
                <td>
                    <a href="#" role="button" data-toggle="modal">
                        Editar
                    </a>
                </td>
            </tr>
        </tbody>
    </table>

    <app-modal-filtro-crear (onCreate)="onCreated($event)"></app-modal-filtro-crear>
</main>