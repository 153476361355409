import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor( private fb: FirebaseService ) { }

  async logIn(credentials: { email:string, password: string }) {
    await new Promise(resolve => {
      this.fb.getAuth().signInWithEmailAndPassword(credentials.email, credentials.password).then( () => {
        resolve(this.fb.getAuth().currentUser?.email);
      }).catch(e => console.log('Error: ', e));
    });
  }

  isLogged() {
    return this.fb.getAuth().currentUser;
  }
}
