import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-perfil-candidato',
  templateUrl: './perfil-candidato.component.html',
  styleUrls: ['./perfil-candidato.component.scss']
})
export class PerfilCandidatoComponent implements OnInit {
  candidato: any;
  pdf: string = '';

  constructor( private routes: ActivatedRoute, private fb: FirebaseService, private sanitizer: DomSanitizer, private ref: ChangeDetectorRef ) { }

  ngOnInit(): void {
    this.routes.params.subscribe( (params: Params ) => {
      this.cargarCandidato(params.id);
    });
  }

  /*ngAfterViewInit() {
    this.ref.detach()
  }*/

  async cargarCandidato(id:string) {
    await this.fb.getUsers(id).then( user => {
      let cv: any = this.sanitizer.bypassSecurityTrustUrl(user.val().cv_file);
      this.candidato = {id: user.key, ...user.val(), cv_files: cv.changingThisBreaksApplicationSecurity};
      //this.pdf = user.val().cv_file.split('?alt=')[0];
      console.log('Usuario:', cv.changingThisBreaksApplicationSecurity);
    });
  }

  getSafeUrl(src: string) {
    this.ref.detach();
    return this.sanitizer.bypassSecurityTrustResourceUrl(src);
  }

}
