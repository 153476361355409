<mat-drawer-container class="example-container" autosize>

    <mat-drawer mode="side" opened>
        <app-nav-menu></app-nav-menu>
    </mat-drawer>

    <mat-drawer-content>
        <app-top-menu></app-top-menu>
        <router-outlet></router-outlet>

        <div style="padding: 25px;">
            <p align="right">2020 Inteligart</p>
        </div>         
    </mat-drawer-content>

</mat-drawer-container>