import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  users: Array<any> = [];
  jobs: Array<any> = [];

  constructor( private fb: UserService ) { }

  ngOnInit(): void {
    this.cargaUsuarios();
    this.cargaVacantes();
  }

  async cargaUsuarios() {
    return this.fb.getUsers().then( (response: any) => {
      this.users = response;
    }).catch()
  }

  async cargaVacantes() {
    return this.fb.getJobs().then( (response: any) => {
      this.jobs = response;
    }).catch()
  }

}
