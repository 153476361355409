import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})
export class FiltrosComponent implements OnInit {
  filters: Array<any> = [];

  constructor( private fb: UserService ) { }

  ngOnInit(): void {
    this.cargaFiltros();
  }

  async cargaFiltros() {
    return this.fb.getFilters().then( (response: any) => {
      this.filters = response;
      this.fb.setDataTables();
    }).catch()
  }

  onCreated(ev: any) {
    console.log('Ev: ', ev);
    this.filters.push(ev);
  }

}
