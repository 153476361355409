<main>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header">
                    <i class="material-icons">person</i> Mis Candidatos
                </div>
                <div class="card-body">
                    <h4 align="center">
                        <a [routerLink]="['/app', 'candidatos']">{{ users.length }}</a>
                    </h4>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-header">
                    <i class="material-icons">person</i> Mis Vacantes
                </div>
                <div class="card-body">
                    <h4 align="center">
                        <a [routerLink]="['/app', 'vacantes']">{{ jobs.length }}</a>
                    </h4>
                </div>
            </div>
        </div>
    </div>
</main>
