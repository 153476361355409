import { Injectable } from '@angular/core';
import app from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyB1mLc-TRv_BP-ujHqUnotVj5yT0jecr6I",
  authDomain: "candidatos-sirius.firebaseapp.com",
  databaseURL: "https://candidatos-sirius.firebaseio.com",
  projectId: "candidatos-sirius",
  storageBucket: "candidatos-sirius.appspot.com",
  messagingSenderId: "329201244280",
  appId: "1:329201244280:web:6b7eda9ae4e91e35e6f58d",
  measurementId: "G-1MZ0SE7NTV"
};

app.initializeApp(firebaseConfig);

@Injectable({
  providedIn: 'root'
})

export class FirebaseService {
  odb = app.database();
  auth = app.auth();

  constructor() { }

  ngOnInit(): void {
  }

  getAuth() {
    return this.auth;
  }

  async getUsers(id?:string) {
    return id ? await this.odb.ref(`/Users/${id}`).get() : await this.odb.ref(`/Users`).get();
  }

  async getJobs(id?: string) {
    return id ? await this.odb.ref(`/Vacantes/${id}`).get() : await this.odb.ref('/Vacantes').get();
  }

  async deleteUser(id: string) {
    return this.odb.ref(`/Users/${id}`).remove();      
  }

  getFilters() {
    return this.odb.ref('/Filtros').get();
  }

  postFilter(id:string, vars: any) {
    return this.odb.ref('/Filtros/' + id).set(vars);
  }
}
