<main *ngIf="candidato && candidato.id">

    <div class="row">
        <div class="col-1">
            <div style="background-color: aqua; color: #ffffff; border-radius: 100%; padding-top: 15px; width: 64px; height: 64px;">
                <p align="center">{{ candidato.name.split('')[0] | uppercase }} {{ candidato.last.split('')[0]| uppercase }}</p>
            </div>
        </div>
        <div class="col-11">
            <h3>{{ candidato.name | titlecase }} {{ candidato.middle | titlecase }} {{ candidato.last | titlecase }}</h3>
            <p class="text-success">Regular</p>
        </div>
    </div>

    
    <div class="row">
        <div class="col-lg-12">
            <h4>Información General de la Vacante</h4>

            <div style="height: 25px;"></div>
            <div class="card">
                <div class="card-header">
                    <strong>Datos Generales</strong>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-4">
                            <p class="text-secondary">Nombre</p>
                            <p>{{ candidato.name | titlecase }}</p>
                        </div>
                        <div class="col-4">
                            <p class="text-secondary">Apellido Paterno</p>
                            <p>{{ candidato.last | titlecase }}</p>
                        </div>
                        <div class="col-4">
                            <p class="text-secondary">Apellido Materno</p>
                            <p>{{ candidato.middle | titlecase }}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <p class="text-secondary">Fecha de Nacimiento</p>
                            <p>{{ candidato.birthdate.split('T')[0] | date }}</p>
                        </div>
                        <div class="col-4">
                            <p class="text-secondary">Expectativas Salariales</p>
                            <p>{{ candidato.cash_past | currency }} - {{ candidato.cash_now | currency }}</p>
                        </div>
                        <div class="col-4">
                            <p class="text-secondary">Teléfono</p>
                            <p>{{ candidato.phone }}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <p class="text-secondary">Disponibilidad</p>
                            <p *ngIf="candidato.always">Inmediata</p>
                            <p *ngIf="!candidato.always">{{candidato.date.split('T')[0] | date}}</p>
                        </div>
                        <div class="col-4">
                            <p class="text-secondary">Tipo de Trabajo</p>
                            <p>
                                <span *ngIf="candidato.type.freelancer">Freelancer</span>
                                <span *ngIf="candidato.type.nomina">Nómina</span>
                                <span *ngIf="candidato.type.both">Indistinto</span>
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-secondary">Especialidad</p>
                            <p> {{ candidato.filter }} / {{ candidato.system }} / {{ candidato.module }} </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <p class="text-secondary">Descripción</p>
                            <p>{{ candidato.description }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div style="height: 25px;"></div>
            <div *ngIf="candidato.cv_file">
                <iframe style="width: 100%; height: auto; min-height: 500px; border:none;" [src]="getSafeUrl(candidato.cv_file)"></iframe>
                <!--<object type="application/pdf" [src]="getSafeUrl(candidato.cv_file)" width="100%" height="200"></object>-->
                <!--<pdf-viewer [src]="candidato.cv_files" [render-text]="true" style="display: block;"></pdf-viewer>-->
            </div>
        </div>        
    </div>

    <div style="height: 45px;"></div>