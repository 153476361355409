import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';

import { uuid } from 'uuidv4';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor( private fb: FirebaseService ) { }

  async getUsers() {
    return await new Promise(resolve => {
      this.fb.getUsers().then( response => {
        let users: Array <any> = [];
        response.forEach( data => {
          users.push({id: data.key, ...data.val()});
        })

        resolve(users);
      }).catch( e => console.log('Error: ', e));
    });
  }

  async getJobs(id?:string) {

    if( !id ){
      return await new Promise(resolve => {
        this.fb.getJobs().then( response => {
          let jobs: Array <any> = [];
          response.forEach( data => {
            jobs.push(data.val());
          })
  
          resolve(jobs);
        }).catch( e => console.log('Error: ', e));
      });
    } else{
      return await new Promise(resolve => {
        this.fb.getJobs(id).then( response => {
          resolve(response.val());
        }).catch( e => console.log('Error: ', e));
      });
    }
    
  }

  async getFilters() {
    return await new Promise(resolve => {
      this.fb.getFilters().then( response => {
        let filters: Array <any> = [];
        response.forEach( data => {
          filters.push(data.val());
        })

        resolve(filters);
      }).catch( e => console.log('Error: ', e));
    });
  }

  async postFilter(data: any) {
    const id = uuid();
    console.log('Llega al user', data);
    return await new Promise(resolve => {
      this.fb.postFilter(id, data).then( () => {
        resolve({...data, id});
      }).catch( e => console.log('Error: ', e));
    });
  }

  setDataTables() {
    $(".dataTables").DataTable().destroy();
    setTimeout(() => {
      $(".dataTables").DataTable({
        order: [],
        language: {
          lengthMenu: "Mostrar _MENU_ registros por página",
          zeroRecords: "No hay resultados",
          info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
          infoEmpty: "No hay resultados",
          infoFiltered: "(filtrados de _MAX_ registros totales)",
          processing: "Procesando...",
          search: "Buscar:",
          paginate: {
            first: "Primera",
            last: "Última",
            next: "Siguiente",
            previous: "Anterior"
          },
          aria: {
            sortAscending: ": activar orden ascendente",
            sortDescending: ": activar orden descendente"
          }
        }
      });
    }, 300);
  }
}
