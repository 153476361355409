import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-mis-vacantes',
  templateUrl: './mis-vacantes.component.html',
  styleUrls: ['./mis-vacantes.component.scss']
})
export class MisVacantesComponent implements OnInit {  
  jobs: Array<any> = [];

  constructor( private fb: UserService ) { }

  ngOnInit(): void {
    this.cargaVacantes();
  }

  async cargaVacantes() {
    return this.fb.getJobs().then( (response: any) => {
      this.jobs = response;
      this.fb.setDataTables();
    }).catch()
  }

}
