import { Component, OnInit } from '@angular/core';
import { Toaster } from 'ngx-toast-notifications';
import { FirebaseService } from 'src/app/services/firebase.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-candidatos',
  templateUrl: './candidatos.component.html',
  styleUrls: ['./candidatos.component.scss']
})
export class CandidatosComponent implements OnInit {
  users: Array<any> = [];

  constructor( private fb: UserService, private fs: FirebaseService, private notifier: Toaster ) { }

  ngOnInit(): void {
    this.cargaUsuarios();
  }

  async cargaUsuarios() {
    return this.fb.getUsers().then( (response: any) => {
      this.users = response;
      console.log('Candidatos ', this.users);
      this.fb.setDataTables();
    }).catch()
  }

  borrarUsuario(id: string) {
    if( id && confirm('¿Estás seguro que deseas eliminar éste usuario?') ) {      
      this.fs.deleteUser(id).then(() => {
        this.notifier.open("El usuario fue eliminado correctamente!", { type: 'success' });
        this.users = this.users.filter( u => u.id !== id);
      }).catch(() => {
        this.notifier.open("Ha ocurrido un problema al eliminar el usuario!", { type: 'danger' });
      });
    }
  }

}
