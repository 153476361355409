import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-vacantes',
  templateUrl: './vacantes.component.html',
  styleUrls: ['./vacantes.component.scss']
})
export class VacantesComponent implements OnInit {
  vacante: any;

  constructor( private routes: ActivatedRoute, private fb: FirebaseService, private us: UserService ) { }

  ngOnInit(): void {
    this.routes.params.subscribe( (params: Params) => {
      //console.log('params', params.id);
      this.cargarVacante(params.id);
    });

    
  }

  async cargarVacante(id: string) {
    await this.fb.getJobs(id).then(response => {
      this.vacante = response.val();
      this.vacante.users = [];

      for( const a in this.vacante.applicants ) {
        this.fb.getUsers(a).then( snapshot => {
          if( snapshot.val() ) {
            //console.log('User', snapshot.val());
            this.vacante.users.push(snapshot.val());
          }          
        });
      }

      this.us.setDataTables();
      
    }).catch();
  }

}
