<div class="modal fade" id="modal-filtro-crear" data-backdrop="false" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"  style="background-color: rgba(0, 0, 0, 0.5);">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Agregar Filtro</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="form" (ngSubmit)="submit()">
                <fieldset>
                    <div class="form-group">
                        <label>Tecnología</label>
                        <input type="text" class="form-control" formControlName="tech" placeholder="Tecnología" />
                    </div>
                    <div class="form-group">
                        <label>Sistema</label>
                        <input type="text" class="form-control" formControlName="system" placeholder="Sistema" />
                    </div>
                    <div class="form-group">
                        <label>Módulo</label>
                        <input type="text" class="form-control" formControlName="module" placeholder="Módulo" />
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary btn-block">Crear</button>
                    </div>
                </fieldset>
            </form>
            
        </div>
            <!--<div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary btn-block">Crear</button>
            </div>-->
        </div>
    </div>
</div>