<main>
    <div class="row">
        <div class="col-10">
            <h1>Mis vacantes</h1>
            <p>Estas son las vacantes que has creado en el sistema. Puedes verlas en detalle, editarlas y crear más vacantes. </p>
        </div>
        <div class="col-2">
            <button type="button" class="btn btn-primary btn-block">Agregar Vacante</button>
        </div>
    </div>

    <table class="table table-hover dataTables">
        <thead>
            <tr>
                <th>Título</th>
                <th>Empresa</th>
                <th>Rango Salarial</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let j of jobs">
                <td>{{j.type}}</td>
                <td>{{j.company}}</td>
                <td>{{j.salary}}</td>
                <td>
                    <a [routerLink]="['/app', 'mis-vacantes', j.index]">
                        Ver Vacante
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</main>

