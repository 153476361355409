import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  links: Array<any> = [
    { to: "dashboard", name: "Dashboard" },
    { to: "candidatos", name: "Candidatos" },
    { to: "mis-vacantes", name: "Mis Vacantes" },
    { to: "filtros", name: "Filtros" },
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
