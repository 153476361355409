<div style="height: 25px;"></div>
<div class="row">
    <div class="col-4">
        <img src="http://inteligart.com/proyectos/assets/sirius/logoicon.png" class="img-fluid" style="z-index: -1;" />  
    </div>
    <div class="col-8">
        <h2>Panel Candidatos</h2>
        <p>Super Admin</p>
    </div>
</div>
<div style="height: 25px;"></div>
<!--
<mat-grid-list cols="2">
    <mat-grid-tile>
      <img src="../../assets/img/logoicon.png" style="width: 100%;" />
    </mat-grid-tile>
    <mat-grid-tile>
        <h2>Panel Candidatos</h2>
    </mat-grid-tile>
</mat-grid-list>
-->

<mat-nav-list>
    <mat-list-item *ngFor="let link of links">
        <a mat-list-item role="navigation" [routerLink]="['/app', link.to]">{{ link.name }}</a>
    </mat-list-item>
</mat-nav-list>

<div style="height: 250px;"></div>
<div class="row">
    <div class="col-6 offset-3">
        <img src="http://inteligart.com/proyectos/assets/sirius/logofull.png" class="img-fluid" style="z-index: -1;" />
        <hr />
    </div>
</div>