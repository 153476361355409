<main>
    <h1>Mis Candidatos</h1>
    <p>Aquí están enlistados los diferentes candidatos que se han registrado en la aplicación.</p>
    <div style="height: 25px;"></div>
    
    <div class="row">
        <div class="col">
            <table class="table table-hover dataTables">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Disponibildad</th>
                        <th>Teléfono</th>
                        <th>Tech</th>
                        <th>System</th>
                        <th>Filtros</th> 
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of users">
                        <td>{{u.name | titlecase}} {{u.last | titlecase}} {{u.middle | titlecase}}</td>
                        <td>
                            <span *ngIf="u.always">Inmediata</span>
                            <span *ngIf="!u.always">{{u.date.split('T')[0] | date}}</span>
                        </td>
                        <td>{{u.phone}}</td>
                        <td>
                            <span class="badge badge-primary block" *ngFor="let f of u.filter">{{f}}</span>
                        </td>
                        <td>
                            <span class="badge badge-info block" *ngFor="let f of u.system">{{f}}</span>
                        </td>
                        <td>
                            <span class="badge badge-dark block" *ngFor="let f of u.module">{{f}}</span>
                        </td>
                        <td>
                            <a [routerLink]="['/app', 'candidatos', u.id]">
                                <i class="material-icons">search</i>
                            </a>
                        </td>
                        <td>
                            <a href="#" data-toggle="modal" role="button" (click)="borrarUsuario(u.id);">
                                <i class="material-icons">delete</i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</main>

