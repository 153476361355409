import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { v4 } from 'uuid';

declare var $:any;

@Component({
  selector: 'app-modal-filtro-crear',
  templateUrl: './modal-filtro-crear.component.html',
  styleUrls: ['./modal-filtro-crear.component.scss']
})
export class ModalFiltroCrearComponent implements OnInit, OnChanges {
  @Output() onCreate = new EventEmitter<any>();
  form: FormGroup;

  constructor( private fb: FirebaseService ) {
    this.form = new FormGroup({
      tech: new FormControl("", Validators.required),
      system: new FormControl("", Validators.required),
      module: new FormControl("", Validators.required)
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  submit() {
    if( this.form.valid ) {
      const id = v4();
      const fecha = new Date();
      const filtro = { ...this.form.value, fecha: fecha.toLocaleDateString() + ' ' + fecha.toLocaleTimeString(), index: id };
      //console.log('new Filter: ', filtro);
      
      this.fb.postFilter(id, filtro).then( () => {
        this.onCreate.emit(filtro);
        $('.modal').modal('hide');
      }).catch();
    } else{
      console.log('Is not valid...', this.form.value);
    }
  }

}
