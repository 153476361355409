<main *ngIf="vacante && vacante.index">
    <div class="row">
        <div class="col-lg-12">
            <h1>{{vacante.title}}</h1>
            <h3>Información General de la Vacante</h3>
            <div style="height: 25px;"></div>
            <div class="card">
                <div class="card-header">
                    <strong>Datos Generales</strong>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-4">
                            <p class="text-secondary">Ubicación</p>
                            <p>{{ vacante.location }}</p>
                        </div>
                        <div class="col-4">
                            <p class="text-secondary">Rango Salarial</p>
                            <p>{{ vacante.salary }}</p>
                        </div>
                        <div class="col-4">
                            <p class="text-secondary">Tipo de Empleo</p>
                            <p>{{ vacante.type }}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <p class="text-secondary">Descripción</p>
                            <p>{{ vacante.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>

    <div style="height: 45px;"></div>

    <div class="row">
        <div class="col-lg-12">
            <h1>Aplicantes</h1>
            <h3>Estos son los aplicantes a esta vacante</h3>
            <div style="height: 25px;"></div>
            <table class="table table-hover dataTables">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Teléfono</th>
                        <th>Expectativas</th>
                        <th>Disponibilidad</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let aplicante of vacante.users">
                        <td>{{ aplicante.name |titlecase }} {{ aplicante.middle | titlecase }} {{ aplicante.last | titlecase }}</td>
                        <td>{{ aplicante.phone }}</td>
                        <td>{{ aplicante.cash_past | currency }} - {{ aplicante.cash_now | currency }}</td>
                        <td>
                            <span *ngIf="aplicante.always">Inmediata</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</main>

