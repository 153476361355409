import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;

  constructor( private auth: AuthService, private router: Router ) {
    this.form = new FormGroup({
        email: new FormControl("", Validators.required),
        password: new FormControl("", Validators.required),
    });
  }

  ngOnInit(): void {
    this.verifyLogin();
  }

  async submit() {
    if( this.form.valid ) {
      await this.auth.logIn(this.form.value).then( user => {
        console.log('User', user);
        this.router.navigate(['/app']);
      }).catch();
    }
  }

  verifyLogin() {
    console.log('this.auth.isLogged(): ', this.auth.isLogged());
    if ( this.auth.isLogged() ) {      
      this.router.navigate(['/app']);
    }
  }

}
