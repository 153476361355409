import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { ToastNotificationsModule, TOAST_NOTIFICATIONS_CONFIG } from 'ngx-toast-notifications';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './auth/login/login.component';
import { SystemComponent } from './system/system.component';
import { DashboardComponent } from './system/dashboard/dashboard.component';
import { CandidatosComponent } from './system/candidatos/candidatos.component';
import { MisVacantesComponent } from './system/mis-vacantes/mis-vacantes.component';
import { FiltrosComponent } from './system/filtros/filtros.component';
import { NavMenuComponent } from './auth/nav-menu/nav-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { TopMenuComponent } from './auth/top-menu/top-menu.component';
import { ModalFiltroCrearComponent } from './modals/filtros/modal-filtro-crear/modal-filtro-crear.component';
import { VacantesComponent } from './system/mis-vacantes/vacantes/vacantes.component';
import { PerfilCandidatoComponent } from './system/candidatos/perfil-candidato/perfil-candidato.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SystemComponent,
    DashboardComponent,
    CandidatosComponent,
    MisVacantesComponent,
    FiltrosComponent,
    NavMenuComponent,
    TopMenuComponent,
    ModalFiltroCrearComponent,
    VacantesComponent,
    PerfilCandidatoComponent
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule, // required
    AppRoutingModule,
    BrowserAnimationsModule, FormsModule, ReactiveFormsModule, DataTablesModule, PdfViewerModule,
    MatSidenavModule, MatGridListModule, MatListModule, MatIconModule, MatButtonModule,    
    ToastNotificationsModule.forRoot({duration: 3000}),
  ],
  providers: [{provide: TOAST_NOTIFICATIONS_CONFIG, useValue: {duration: 3000, type: 'primary'}},],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
