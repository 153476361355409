<mat-drawer-container class="example-container" autosize>

    <mat-drawer mode="side" opened>
    </mat-drawer>

    <mat-drawer-content>
        <div class="row">
            <div class="col-lg-3 offset-lg-9">
                <img src="http://inteligart.com/proyectos/assets/sirius/logoicon.png" class="img-fluid" />
            </div>
        </div>

        <h1>Bienvenido</h1>
        <p>Ingresa los datos de tu cuenta para ingresar a la plataforma</p>
        <form [formGroup]="form" (ngSubmit)="submit()">
            <fieldset>
                <div class="form-group">
                    <input type="email" class="form-control" placeholder="Email" formControlName="email" />
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" placeholder="Password" formControlName="password" />
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-block btn-primary">Enviar</button>
                </div>
            </fieldset>
        </form>
    </mat-drawer-content>

</mat-drawer-container>